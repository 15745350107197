//
// _badges.scss
//
.badge {
    box-shadow: $box-shadow;
}

// Lighten badge
@mixin badge-variant-light($bg) {
    color: $bg;
    background-color: rgba($bg, 0.18);
    box-shadow: none;
}

// Outline badge

@mixin badge-variant-outline($bg) {
    color: $bg;
    border: 1px solid $bg;
    background-color: transparent;
    box-shadow: none;
}


@each $color, $value in $theme-colors {
    // Lighten badge (soft)
    .badge-soft-#{$color} {
        @include badge-variant-light($value);
    }

    // Outline badge
    .badge-outline-#{$color} {
        @include badge-variant-outline($value);
    }
}

.badge {
    vertical-align: middle;
    // line-height: inherit !important;
    // line-height: 17px !important;
    // line-height: 140% !important;
    line-height: normal !important;
    padding: 4px 6px 4px 6px;

    &.badge-lg {
        padding: $badge-padding-y * 2 $badge-padding-x * 2.5;
        font-size: $badge-font-size + 0.1em;
    }
}

.badge-outline-unassigned {
    color: #959595;
    border: 1px solid #959595;
    background-color: transparent;
    box-shadow: none;
  }
  .badge-outline-unhandled {
    color: #f5989d;
    border: 1px solid #f5989d;
    background-color: transparent;
    box-shadow: none;
  }
  .badge-outline-in-progress {
    color: #fdc689;
    border: 1px solid #fdc689;
    background-color: transparent;
    box-shadow: none;
  }
  .badge-outline-hot-prospect {
    color: #a8d9b9;
    border: 1px solid #a8d9b9;
    background-color: transparent;
    box-shadow: none;
  }
  .badge-outline-future-prospect {
    color: #c5e09d;
    border: 1px solid #c5e09d;
    background-color: transparent;
    box-shadow: none;
  }
  .badge-outline-applied {
    color: #7accc8;
    border: 1px solid #7accc8;
    background-color: transparent;
    box-shadow: none;
  }
  .badge-outline-succeed {
    color: #bd8cbf;
    border: 1px solid #bd8cbf;
    background-color: transparent;
    box-shadow: none;
  }
  .badge-outline-non-prospect {
    color: #f5989d;
    border: 1px solid #f5989d;
    background-color: transparent;
    box-shadow: none;
  }
  .badge-outline-aborted {
    color: #959595;
    border: 1px solid #959595;
    background-color: transparent;
    box-shadow: none;
  }
  .badge-outline-cancelled {
    color: #959595;
    border: 1px solid #959595;
    background-color: transparent;
    box-shadow: none;
  }
  
  .badge-applying {
    background: #7accc8 !important;
  }
  .badge-paid {
    background: #c5e09d !important;
  }
  .badge-clo {
    background: #fdc689 !important;
  }
  .badge-flo {
    background: #fcb056 !important;
  }
  .badge-visa {
    background: #a8d9b9 !important;
  }
  .badge-completed {
    background: #7accc8 !important;
  }
  .badge-withdrawn {
    background: #959595 !important;
  }
  .badge-cancelled {
    background: #f5989d !important;
  }
  .badge-rejected {
    background: #f5989d !important;
  }

  // General
  .badge-gray {
    background: #E4EAED !important;
    color: #5e5e5e !important;
  }