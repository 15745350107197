.px-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.form-check-input {
  border: 1px solid #333333 !important;
}

.me-3px {
  margin-right: 3px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

img.img-remove {
  cursor: pointer;
  opacity: 0;
}
img.img-remove:hover {
  opacity: 1;
}

.w-100px {
  width: "100px !important";
}

.ReactTable .rt-resizable-header {
  padding: 6px 24px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ReactTable .rt-resizer:before {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 3px;
  height: 18px;
  width: 18px;
  color: transparent;
  content: ".";
  background-size: 18px 18px;
  background-repeat: no-repeat;
  opacity: 0.87;
}

.ReactTable .rt-resizable-header-content:after {
  position: absolute;
  right: 8px;
  top: 3px;
  height: 18px;
  width: 18px;
  z-index: 120;
  color: transparent;
  content: ".";
}

// .table > tbody > tr > td {
//   vertical-align: middle;
// }

// .nav-link-sm {
//   padding: var(--ct-nav-link-padding-y) var(--ct-nav-link-padding-x);;
// }

.nav-link-sm {
  font-size: 12px !important;
  padding: 5px 10px !important;
}

// .container {
//   margin-left: 0!important;
//   margin-right: 0!important;
// }

// .row-flex {
//   display: flex;
//   flex-wrap: wrap;
// }

// .flex-even {
//   flex: 1;
// }

.form-control::-webkit-input-placeholder {
  color: #aab0b9 !important;
  // font-weight: normal !important;
}
.form-control:-moz-placeholder {
  color: #aab0b9 !important;
  // font-weight: normal !important;
}
.form-control::-moz-placeholder {
  color: #aab0b9 !important;
  // font-weight: normal !important;
}
.form-control:-ms-input-placeholder {
  color: #aab0b9 !important;
  // font-weight: normal !important;
}

.form-label {
  font-weight: 400;
  margin-bottom: 0.3rem;
  color: #555c63;
}

/**
    * Reinstate scrolling for non-JS clients
    */
.simplebar-content-wrapper {
  scrollbar-width: 100px;
  -ms-overflow-style: auto;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: initial;
  width: initial;
  height: initial;
}

.comment-editor {
  min-height: 100px;
}

// Hide blink cursor react datepicker
.react-datepicker-input {
  color: transparent !important;
  text-shadow: 0 0 0 #6c757d !important;

  &:focus {
      outline: none !important;
  }
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.track-order-list ul li.hide-border {
  border-left: 2px solid #ffffff;
}

.bg-application-active {
  background: #e8e5ff !important;
}

.border-application {
  border: 1px solid #dcdfe3 !important;
}

// Mentions
.mentions {
  margin: 1em 0;
}

.mentions--singleLine .mentions__control {
  // font-family: monospace;
  font-size: 14px;
  color: #f7f7f7;
  height: 35px;
}
.mentions--singleLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  // min-height: 35px;
}
.mentions--singleLine .mentions__input {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 9px;
  outline: 0;
  padding: 0.45rem 0.9rem;
  color: #6c757d;
}

.mentions--multiLine .mentions__control {
  // font-family: monospace;
  font-size: 14px;
  color: #f7f7f7;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 100px;
}
.mentions--multiLine .mentions__input {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 9px;
  outline: 0;
  padding: 0.45rem 0.9rem;
  color: #6c757d;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 12px;

  // Scroll
  max-height: 100px;
  overflow-y: auto;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #f3f7f9;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  // color: blue !important;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}